<template>
  <div class="bespoke-software">
    <div class="container-fluid">
      <div class="container p-5">
        <h1
          class="pb-5"
          v-animate-onscroll.repeat="'animate__animated animate__bounceIn'"
        >
          Bespoke Software
        </h1>
        <div class="row">
          <div class="col-md-12">
            <p class="bs-first-item">
              At Zim Digital we are passionate about building bespoke software
              that answers the requirements of our clients. We have over 10
              years experience in building enterprise level applications ranging
              from internal apps that automate everyday business tasks to large
              public facing websites that can scale and maintain performance
              based on demand.
            </p>
            <p>
              No project is big or too small for our team. Applications can be
              designed and developed to meet any requirements. We are firm
              believers of automation and feel that any business process that
              can be automated with software will eventually produce a return on
              investment - saving time saves money. There are no limitations
              into the software we can build. Here are a few examples of what we
              can do;
            </p>
            <ul>
              <li class="pb-3">
                Automating accounting spreadsheets - Many organizations still
                find themselves manually trying to manage their monthly finances
                on a monolithic spreadsheet. This can become hard to work with,
                vulnerable to mistakes and this can ultimately be costly and
                extremely time consuming on a monthly basis. We can supply a
                solution to this that will produce the same outcome but with the
                click of a few buttons, saving you hours each month.
              </li>
              <li class="pb-3">
                Order Management Software - Find yourself still managing orders
                and stock on a spreadsheet or paper? Let us help! We will
                identify all your current stock, load your new software with
                data and provide you with a website that will allow you to
                manage orders and stock. We help put your business in a much
                more organised position. We can also add additional ‘nice to
                have’ features such as instant reporting on orders/stock or even
                notifications when stock levels are getting low.
              </li>
              <li class="pb-3">
                Bespoke Website - You have an idea of a product that you want to
                put out to the market however, this isn’t your standard website.
                With your concept, there are no off-the-shelf solutions that
                meet your needs. This is where we come in. We can build any
                website tailored to your requirements and specifications to help
                bring your dreams to life.
              </li>
            </ul>
            <p>
              Have a project that you think will interest us, get in touch!
            </p>
            <p>
              We work Agile on all of our projects, believing that frequent
              feedback is key in delivering clients a product that meets their
              needs and service that is invaluable. Throughout our process, we
              continually liaise with clients and provide them with a platform
              to monitor project updates, progress and to provide an early feel
              for the product - think of this as your playground.
            </p>
            <p>
              We always focus on delivering the minimum valuable product before
              any additional ‘nice to have’ features are added, to ensure
              clients are benefiting from a return on investment from the
              get-go.
            </p>
            <p>
              All our projects are configured with automation processes that
              allow us to regularly deliver changes and updates to your software
              with the confidence that everything works as expected. This also
              allows us to put your changes live with the click of a button, so
              you can beat your competitors to the market and won’t have to wait
              around like others for the next scheduled release date.
            </p>
            <p>
              All our projects are developed with care and testing in mind. We
              strive to ensure that there are no bugs with the software we
              provide, we ensure that these are identified before the software
              goes live. On our side of the process, we confidently use a range
              of techniques to ensure that when we release your product,
              everything works as required.
            </p>
            <p class="bs-last-item">
              Once your product is built and the papers have been signed, the
              software is completely yours. Under contract we will provide
              around-the-clock support as well as, dedicated time each month to
              maintain and update your software.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BespokSoftware",
  beforeCreate() {
    // let footer = document.getElementById("footer");
    // footer.classList.add("fixed-bottom");
  },
  mounted() {
    this.$ga.page("/bespoke-software");
  },
  beforeDestroy() {
    // let footer = document.getElementById("footer");
    // footer.classList.remove("fixed-bottom");
  },
};
</script>

<style lang="scss" scoped>

//import bootstrap and bootstrap-vue required varaibles
@import "~bootstrap/scss/bootstrap.scss";

.bespoke-software {
  margin-top: 5rem;
}

@include media-breakpoint-down(sm) {
  .bespoke-software {
    margin-top: 2rem;
  }
}

.container-fluid {
  color: $zim-white;
}

h1 {
  font-weight: 900;
  color: $zim-blue;
}

.bs-first-item {
  position: relative;
}

.bs-first-item:before {
  content: "";
  position: absolute;
  width: 45%;
  height: 5px;
  background-color: $zim-pink-strong;
  top: 25%;
  left: -50%;
}

.bs-last-item:before {
  content: "";
  position: absolute;
  width: 45%;
  height: 5px;
  background-color: $zim-blue;
  // top: 25%;
  right: -50%;
}

ul li {
  list-style-type: none;
}

ul li::before {
  content: url("~@/assets/discs/disc-pink.svg");
  display: inline-block;
  margin-right: 10px;
  width: 25px;
}

ul li:nth-child(2)::before {
  content: url("~@/assets/discs/disc-blue.svg");
}

ul li:nth-child(3)::before {
  content: url("~@/assets/discs/disc-white.svg");
}

// XX-Large devices (larger desktops, 1500px and up)
@media (min-width: 1500px) {
  .bs-first-item:before {
    left: -100%;
  }
  .bs-last-item:before {
    right: -100%;
  }
}
</style>
