<template>
  <div class="privacy-policy">
    <div class="container-fluid">
      <div class="container p-5">
        <h1
          class="pb-5"
          v-animate-onscroll.repeat="'animate__animated animate__bounceIn'"
        >
          Privacy Policy
        </h1>
        <div class="row">
          <div class="col-md-12">
            <h2>1. Introduction</h2>

            <p>
              Zim Digital Ltd is committed to respecting your privacy and
              protecting your personal information. We make it our priority to
              ensure your personal data is processed lawfully, fairly and
              accurately.
            </p>
            <ul>
              <li>
                We are committed to being transparent about the information we
                collect from you and what we will do with it.
              </li>
              <li>
                We have measures in place to protect your information and keep
                it secure.
              </li>
              <li>
                We respect your rights and aim to give you control over your own
                information.
              </li>
              <li>
                We only hold data that is relevant and necessary,
              </li>
              <li>
                We will take reasonable steps to ensure that the personal data
                we hold is not incorrect or misleading and we will take
                reasonable steps to rectify it, if it is.
              </li>
              <li>
                We commit to review and if necessary, amend our procedures for
                protecting your privacy.
              </li>
            </ul>
            <p>
              Our full Privacy Policy is below which will help you to understand
              how we use your personal information. If you have any questions
              regarding our privacy notice, then you can get in touch by
              emailing
              <a href="mailto:hello@zimdigital.co.uk">hello@zimdigital.co.uk</a>
              or writing to Zim Digital Ltd, 58 Wigan Road, Shevington, Wigan,
              WN6 8AP
            </p>

            <h2>2. Who Are We?</h2>
            <h3>Contact Details</h3>
            <ul>
              <li>Zim Digital Ltd</li>
              <li>
                <a href="mailto:hello@zimdigital.co.uk"
                  >hello@zimdigital.co.uk</a
                >
              </li>
              <li>Christy Aspey – Finance Director</li>
            </ul>
            <p>
              We collect, use and are responsible for certain personal
              information about you. When we do so we are regulated under the
              General Data protection Regulations which applies across the
              European Union (including the United Kingdom) and we are
              responsible as a controller of that personal information for the
              purposes of those laws.
            </p>

            <h2>3. What information do we collect?</h2>
            <p>
              We collect information directly from yourself when you use our
              services either by making an enquiry or making an order with us.
              We collect your name, email address, telephone number, and
              business details when you make an enquiry with us or pay for our
              services. We currently only accept payment via bank transfer
              therefore we do not store any of your bank details.
            </p>
            <p>
              We also collect records of email correspondence (including further
              information we may request from you) when you contact us to
              request information, report a problem, or provide feedback on our
              products and services.
            </p>

            <h2>4. How do we use personal information?</h2>
            <p>
              The main purposes for which we use your personal information are:
            </p>
            <ul>
              <li>
                To respond to the questions that have been submitted via the
                contact form on the website or via emails
              </li>
              <li>
                We also use your information for internal record keeping,
                billing, and accounting and market research purposes.
              </li>
              <li>
                If you have sent us any queries via email or phone regarding our
                website or our products/services we will use your information to
                respond to you
              </li>
              <li>
                If you give us feedback (in the form of testimonials and
                photographs), we may use it to improve our services and we may
                publish it online or offline to promote our business and our
                services. We will ask for permission before publishing.
              </li>
              <li>To communicate with you if you use our services.</li>
            </ul>
            <h2>
              5. What legal basis do we have for processing your personal data?
            </h2>
            <p>
              Zim Digital Ltd will only process your personal information where
              we have a legal basis to do so and this will require the reason or
              reasons Zim Digital Ltd needs or collects your personal
              information to be necessary for processing.
            </p>
            <p>
              The reason or reasons Zim Digital Ltd needs your personal
              information are:
            </p>
            <ul>
              <li>
                Because we require your information so that we can perform the
                contract we have with you, i.e. your order.
              </li>
              <li>
                Because we need to use your personal information to comply with
                a legal obligation.
              </li>
              <li>
                Because we need to respond to a query or quote you have
                requested via our website contact form or via other
                communication methods.
              </li>
              <li>
                Because we have accessed that we have a legitimate reason to do
                so and these interests aren’t overridden by the rights and
                freedoms of you.
              </li>
            </ul>
            <h2>6. When do we share personal data?</h2>
            <p>
              Zim Digital Ltd will treat all personal data confidentially and we
              will not share your information unless consent has been given. We
              only use your data as set out above.
            </p>
            <p>We do not sell personal information to third parties.</p>
            <h2>7. How do we secure personal data?</h2>
            <p>
              We use several different technologies and procedures to ensure we
              protect your personal information to the highest level. We protect
              against, unauthorised access to your data, accidental loss of your
              data, destruction or disclosure of your data and theft of your
              data. We secure data by having:
            </p>
            <ul>
              <li>
                Locked head office premises, documents including personal
                information are stored in a locked filing cabinet.
              </li>
              <li>
                Desktops and laptops that contain personal information are
                password protected, these are changed at 6 monthly intervals.
              </li>
              <li>
                Laptops are only taken off head office when it is necessary for
                work purposes.
              </li>
              <li>
                We have limited the personnel that have access to your personal
                data.
              </li>
              <li>
                Use secure methods to transfer your data (only if requested).
              </li>
              <li>Store your data on a secure cloud-based system.</li>
              <li>Password protecting your personal information.</li>
              <li>
                Conduct privacy impact assessments in accordance with the law
                and our business policies.
              </li>
            </ul>
            <h2>8. How long do we keep your personal data for?</h2>
            <p>
              How long we keep your information depends on the purpose of us
              using your information. Where you have an order booked with us, we
              will keep the information relating to that order so that we can
              fulfil that order. We cannot specify how long we will keep this
              data for, as each order is unique however we have criteria set out
              to determine how long to keep the data for. We will check local
              laws and contractual obligations and we will regularly review our
              data to ensure it is retained for longer than is required.
            </p>
            <ul>
              <li>
                We will keep supplier details for a maximum of 6 years to comply
                with accounting legislation.
              </li>
              <li>
                We keep a data retention schedule that describes how long each
                type of data will be kept.
              </li>
              <li>
                Any hard copies of your data are securely shredded at our head
                office site only by those authorised to access it.
              </li>
            </ul>
            <p>
              Electronic versions of your personal data are held on a secure
              cloud based system that can only be accessed by authorised
              personnel, your data will be deleted by that authorised personnel
              so that it no longer remains on the cloud system and then further
              deleted from the recycle bin to ensure there is no trace of this
              information.
            </p>
            <h2>9. What are cookies?</h2>
            <p>
              Cookies are very small, completely safe and secure text files
              which are downloaded to your computer or mobile device when you
              visit a website or application. Your web browser (For example;
              Safari, Internet Explorer, Google Chrome or Mozilla Firefox) then
              sends these files back to the website or application every time
              you return so that your user personal settings can be recognised.
              Cookies are useful and help website developers and companies
              improve your website browsing experience. Some cookies are
              strictly necessary to enable you to move around the site or to
              provide certain basic features. Other types of cookies are used to
              monitor and improve our website's functionality and performance.
            </p>
            <h2>10. How we use cookies?</h2>
            <p>
              Zim Digital strives to provide you with the best user experience
              possible when visiting our website. We therefore use cookies for
              example to:
            </p>
            <ul>
              <li>Remember your settings during and between visits.</li>
              <li>
                Allow interaction between our website and social networks like
                Instagram.
              </li>
              <li>
                Gather anonymous data about our users and the way they interact
                with our website to serve relevant and functional content.
              </li>
              <li>Continually improve our website for you.</li>
              <li>
                We do not sell the information collected by cookies, nor do we
                disclose the information to third parties, except where required
                by law (for example to government bodies and law enforcement
                agencies).
              </li>
            </ul>
            <h2 id="cookie-policy">11. What cookies we use on our website?</h2>
            <table class="table">
              <thead>
                <tr>
                  <th>Cookie Name</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>_ga</td>
                  <td>
                    Registers a unique ID that is used to generate statistical
                    data on how the visitor uses the website.
                  </td>
                </tr>
                <tr>
                  <td>_gat</td>
                  <td>Used by Google Analytics to throttle request rate.</td>
                </tr>
                <tr>
                  <td>_gid</td>
                  <td>
                    Registers a unique ID that is used to generate statistical
                    data on how the visitor uses the website.
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              We assume your consent to the use of cookies when you visit and
              use our website and most browsers are automatically set to accept
              them. To opt out of being tracked by Google Analytics across all
              websites,
              <a href="#" @click.prevent="disableGoogleAnalytics()"
                >click here</a
              >.
            </p>
            <h2>12. Your rights in relation to personal data</h2>
            <p>
              Under the General data protection regulation, you have certain
              rights in relation to your personal information. If you wish to
              exercise your rights, you can request this via our ‘how to contact
              us’ section. We aim to respond to your request within two weeks of
              receiving that request, if the request is particularly
              complicated, we may extend the deadline. We may require identity
              verification before we are able to carry out your right, this may
              include photo identification. This will only be used to process
              your request.
            </p>
            <p>Your rights include the following:</p>
            <ul>
              <li>
                You have the right to obtain a copy of the personal data that we
                hold. This includes the reasons we are holding your personal
                data, any recipients of the data and the retention period for
                storing your personal data. We will provide a copy of your
                personal data via a secure remote system.
              </li>
              <li>
                You have the right to obtain a copy of the personal data that we
                hold. This includes the reasons we are holding your personal
                data, any recipients of the data and the retention period for
                storing your personal data. We will provide a copy of your
                personal data via a secure remote system.
              </li>
              <li>
                If you believe the personal information we hold is inaccurate or
                incomplete, you have the right to restrict our processing of
                your personal data why we verify or amend the data.
              </li>
              <li>
                You have the right to have us securely transfer the personal
                data you have provided to us, to another controller.
              </li>
              <li>
                You have the right to make a complaint to the Information
                Commissioner's Office, details are below.
              </li>
            </ul>
            <p>
              We will handle all requests, accordingly, however depending on the
              right you wish to exercise, and the nature of the personal
              information involved, there may be legal reasons why we cannot
              grant your request. Data subject rights may be limited in certain
              circumstances:
            </p>
            <ul>
              <li>
                If the data subject request exposes personal data about another
                person.
              </li>
              <li>
                If we are asked to delete data that we are required to keep by
                law.
              </li>
              <li>
                We may need to retain certain elements of data that relate to a
                contract between you and Zim Digital Ltd because we need it for
                our own legal and auditing purposes.
              </li>
            </ul>
            <h2>13. Validity and document management</h2>
            <p>This document is valid as of April 2021</p>

            <p>
              The owner of this document is the Data Protection Officer, who
              must check and, if necessary, update the document at least once a
              year.
            </p>

            <h2>14. How to contact us?</h2>
            <p>
              If you wish to exercise your rights, have any questions or
              concerns about our privacy practice or wish to complain, please
              contact us on the below details:
            </p>
            <p>
              Email Address:
              <a href="mailto:hello@zimdigital.co.uk">hello@zimdigital.co.uk</a>
            </p>
            <p>
              Alternatively, you can send your request in writing to the below
              address:
            </p>
            <ul>
              <li>Zim Digital Ltd</li>
              <li>58 Wigan Road</li>
              <li>Shevington</li>
              <li>Wigan</li>
              <li>WN6 8AP</li>
            </ul>
            <p>
              You have the right to make complaints to the UK’s independent
              authority for data protection, the Information Commissioner's
              Office (ICO). There address details are below:
            </p>
            <ul>
              <li>Information Commissioner’s Office</li>
              <li>Wycliffe House</li>
              <li>Water Lane</li>
              <li>Wilmslow</li>
              <li>Cheshire</li>
              <li>SK9 5AF</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PrivacyPolicy",
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "Privacy Policy",
  },
  mounted() {
    this.$ga.page("/privacy-policy");
    this.overridgeTopbarBackgroundColor();
  },
  methods: {
    disableGoogleAnalytics: function() {
      this.$ga.disable();
    },
    overridgeTopbarBackgroundColor: function() {
      var topbarHeader = document.getElementById("topbar-header");
      topbarHeader.classList.add("topbar-header-override");
    },
  },
};
</script>

<style lang="scss" scoped>
.container-fluid {
  color: $zim-white;
  padding-top: 100px; //required to add spacing to allow to static navbar
}

h1 {
  font-weight: 900;
  color: $zim-blue;
}

a {
  color: $zim-pink;
}

table {
  color: $zim-white;
}
</style>
