import { render, staticRenderFns } from "./BespokeSoftware.vue?vue&type=template&id=02a4ffee&scoped=true&"
import script from "./BespokeSoftware.vue?vue&type=script&lang=js&"
export * from "./BespokeSoftware.vue?vue&type=script&lang=js&"
import style0 from "./BespokeSoftware.vue?vue&type=style&index=0&id=02a4ffee&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02a4ffee",
  null
  
)

export default component.exports