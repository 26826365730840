<template>
  <div
    :id="id"
    class="col-lg-4 col-md-10 m-4 p-4 package d-flex flex-column"
    :class="isLightTheme ? 'light-theme' : 'dark-theme'"
  >
    <div class="row align-items-center">
      <div class="col-2">
        <img
          class="img-responsive mx-auto d-block mb-4"
          :src="iconSrc"
          :alt="iconAlt"
        />
      </div>
      <div class="col-10">
        <h3 class="mb-4">{{ title }}</h3>
      </div>
    </div>

    <slot>
      <p>Please provide service description</p>
    </slot>
  </div>
</template>

<script>
export default {
  name: "ServiceCard",
  props: {
    id: String,
    iconSrc: String,
    iconAlt: String,
    title: String,
    isLightTheme: Boolean,
  },
};
</script>

<style lang="scss" scoped>
h3 {
  color: $zim-blue;
}

a {
  color: $zim-blue !important;
}

.package {
  border-radius: 10px;

  ul {
    list-style-type: none;
    color: $zim-text-dark;
    padding-inline-start: 15px !important;
  }

  ul li {
    overflow-wrap: break-word;
  }
}

.light-theme {
  background-color: $zim-white !important;
  p {
    color: $zim-text-dark !important;
  }
}

.dark-theme {
  background-color: $zim-navy;
  p {
    color: $zim-white;
  }

  ul {
    list-style-type: disc;
    color: $zim-white;
    padding-inline-start: 2rem !important;
  }

  ul li {
    overflow-wrap: break-word;
  }
}
</style>
