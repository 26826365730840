<template>
  <div>
    <div id="packages" class="container-fluid">
      <div class="container">
        <div class="row">
          <div class="col">
            <h2
              class="text-center"
              v-animate-onscroll.repeat="'animate__animated animate__fadeIn'"
            >
              Our Packages<span>:</span>
            </h2>
          </div>
        </div>
        <div class="row m-5 packages-text">
          <p class="text-center">
            We offer a range of packages that specialise in providing your brand
            with a competitive online presence that will get you noticed. Each
            package can be tailored to your requirements. With all our packages,
            we offer round-the-clock service and support. Can’t see what you’re
            looking for?
            <router-link
              to="#contact"
              v-scroll-to="{
                el: '#contact',
                offset: 0,
              }"
              class="get-in-touch"
              >Get in touch!</router-link
            >
          </p>
        </div>
        <div class="row justify-content-center package-sections">
          <div class="col-lg-3 col-md-6 m-4 p-2 package package-1">
            <h3 class="mb-3">Essentials</h3>
            <ul>
              <li>Branding & Content</li>
              <li>Logo's</li>
              <li>Printed Graphics</li>
              <li>Social Media Graphics</li>
              <li>Single Page Website</li>
              <li>Domain & Hosting</li>
              <li>Custom Emails</li>
              <li>Website Analytics</li>
              <li>Around-the-clock Support</li>
              <li>Maintenance & Updates</li>
            </ul>
            <div class="row">
              <div class="col text-center learn-more pb-2">
                <button
                  @click="
                    setPackageProperties(essentialsTitle, essentialsPackageText)
                  "
                  v-b-modal.packages-modal
                  class="btn btn-learn-more"
                >
                  Learn More...
                </button>
              </div>
              <div class="col text-center enquire-now pb-2">
                <router-link
                  to="#contact"
                  v-scroll-to="'#contact'"
                  class="btn btn-enquire"
                  >Enquire Now!</router-link
                >
              </div>
            </div>
          </div>

          <div
            class="col-lg-3 col-md-6 m-4 p-2 package package-2 d-flex flex-column"
          >
            <h3 class="mb-3">Enterprise</h3>
            <p class="pl-3">Everything from our 'Essentials' package plus:</p>
            <ul>
              <li>Multi-Page Website</li>
              <li>Content Management System (CMS)</li>
              <li>Training & Support on CMS</li>
            </ul>
            <div class="row mt-auto">
              <div class="col text-center learn-more pb-2">
                <button
                  @click="
                    setPackageProperties(enterpriseTitle, enterprisePackageText)
                  "
                  v-b-modal.packages-modal
                  class="btn btn-learn-more"
                >
                  Learn More...
                </button>
              </div>
              <div class="col text-center enquire-now pb-2">
                <router-link
                  to="#contact"
                  v-scroll-to="'#contact'"
                  class="btn btn-enquire"
                  >Enquire Now!</router-link
                >
              </div>
            </div>
          </div>

          <div
            class="col-lg-3 col-md-6 m-4 p-2 package package-3 d-flex flex-column"
          >
            <h3 class="mb-3">ECommerce</h3>
            <p class="pl-3">Everything from our 'Enterprise' package plus:</p>
            <ul>
              <li>Ecommerce Store</li>
              <li>Training & Support of ECommerce Store</li>
              <li>Unlimited Payment Integrations</li>
            </ul>
            <div class="row mt-auto">
              <div class="col text-center learn-more pb-2">
                <button
                  @click="
                    setPackageProperties(ecommerceTitle, ecommercePackagText)
                  "
                  v-b-modal.packages-modal
                  class="btn btn-learn-more"
                >
                  Learn More...
                </button>
              </div>
              <div class="col text-center enquire-now pb-2">
                <router-link
                  to="#contact"
                  v-scroll-to="'#contact'"
                  class="btn btn-enquire"
                  >Enquire Now!</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <PackagesModel
      :title="selectedPackageTitle"
      :modalContent="selectedPackageText"
    />
  </div>
</template>

<script>
import PackagesModel from "./modals/PackagesModel.vue";

let essentialsTitle = "Essentials Package";
let enterpriseTitle = "Enterprise Package";
let ecommerceTitle = "Ecommerce Package";

let essentialsPackageText =
  "Our starting package 'Essentials' will digitalise your brand, providing you with a website, domain, branding, logo and social media content, plus much more. This is perfect for clients who would like to bring an idea to reality, digitalise an existing business or even a rebrand an existing business.";
let enterprisePackageText =
  "The 'Enterprise' package extends our 'Essentials' package and is perfect for the business that requires a more content heavy website. This package comes with a content management system (CMS) that will allow the client to edit the content of thier website themselves.";
let ecommercePackagText =
  "The 'Ecommerce' package contains all the niceties of our other packages but provides you with a platform to sell products online.";

export default {
  name: "Packages",
  components: {
    PackagesModel,
  },
  data() {
    return {
      essentialsTitle: essentialsTitle,
      essentialsPackageText: essentialsPackageText,
      enterpriseTitle: enterpriseTitle,
      enterprisePackageText: enterprisePackageText,
      ecommerceTitle: ecommerceTitle,
      ecommercePackagText: ecommercePackagText,
      selectedPackageTitle: "",
      selectedPackageText: "",
    };
  },
  methods: {
    setPackageProperties(packageTitle, packageText) {
      this.selectedPackageTitle = packageTitle;
      this.selectedPackageText = packageText;
    },
  },
};
</script>

<style lang="scss" scoped>
.container-fluid {
  background-color: $zim-navy;
  color: $zim-white;
}

h2 > span {
  color: $zim-blue;
}

h3 {
  text-align: center;
  font-weight: bold;
}

.get-in-touch {
  color: $zim-pink;
}
.get-in-touch:hover {
  color: $zim-white;
}

.package {
  background-color: $zim-white;
  border-radius: 10px;

  p {
    color: #212529;
  }

  ul {
    list-style-type: none;
    color: $zim-text-dark;
    padding-inline-start: 15px !important;
  }

  ul li {
    overflow-wrap: break-word;
  }
}

//todo: style package text todo (get in touch)

.package-1 {
  h3 {
    color: $zim-orange-strong;
  }

  ul li::before {
    content: url("~@/assets/discs/disc-orange.svg");
    display: inline-block;
    margin-right: 10px;
    width: 18px;
  }

  .btn-enquire {
    background-color: $zim-orange;
  }
}

.package-2 {
  h3 {
    color: $zim-blue;
  }

  ul li::before {
    content: url("~@/assets/discs/disc-blue.svg");
    display: inline-block;
    margin-right: 10px;
    width: 18px;
  }

  .btn-enquire {
    background-color: $zim-blue;
  }
}

.package-3 {
  h3 {
    color: $zim-pink;
  }

  ul li::before {
    content: url("~@/assets/discs/disc-pink.svg");
    display: inline-block;
    margin-right: 10px;
    width: 18px;
  }

  .btn-enquire {
    background-color: $zim-pink;
  }
}

.btn-enquire {
  background-color: $zim-orange; //default - get's overriden
  color: $zim-white;
  border: none;
  border-radius: 25px;
  padding: 5px 20px 5px 20px;
  white-space: nowrap;
  font-size: 0.8rem;
}

.btn-enquire:hover,
.btn-enquire:focus,
.btn-enquire:active,
.btn-enquire:active:focus {
  color: $zim-white;
  background-color: $zim-pink;
  border-color: none !important;
  outline: none !important;
  box-shadow: none;
}

.btn-learn-more {
  background-color: $zim-white;
  color: $zim-navy;
  border: 1px $zim-navy solid;
  border-radius: 25px;
  padding: 5px 20px 5px 20px;
  white-space: nowrap;
  font-size: 0.8rem;
}

.btn-learn-more:hover,
.btn-learn-more:focus,
.btn-learn-more:active,
.btn-learn-more:active:focus {
  color: $zim-white;
  background-color: $zim-pink;
  border: none !important;
  outline: 0 !important;
  box-shadow: none;
  font-size: 0.8rem;
}

.learn-more {
  padding-left: 1.2rem;
}

.enquire-now {
  padding-right: 1.2rem;
}

// Medium devices and up (tablets, 768px and up)
@media screen and (min-width: 768px) {
  .section > .row:nth-child(2) {
    position: relative;
    top: 5%;
  }
}

// Small devices and below (landscape phones, 576px and below)
@media screen and (max-width: 576px) {
  .learn-more {
    padding-left: 1rem;
  }

  .enquire-now {
    padding-right: 1rem;
  }

  .packages-text {
    margin-bottom: 0.5rem !important;
  }

  .package-sections {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
}
</style>
