<template>
  <ul>
    <li>
      <router-link
        to="#section-service-group-1"
        v-scroll-to="'#section-service-group-1'"
        ><span
          class="dot"
          :class="[(isLightTheme ? 'light-theme' : 'dark-theme'), (activeIndex === 1 ? 'active' : '')]"
        ></span
      ></router-link>
    </li>
    <li>
      <router-link
        to="#section-service-group-2"
        v-scroll-to="'#section-service-group-2'"
        ><span
          class="dot"
          :class="[(isLightTheme ? 'light-theme' : 'dark-theme'), (activeIndex === 2 ? 'active' : '')]"
        ></span
      ></router-link>
    </li>
    <li>
      <router-link
        to="#section-service-group-3"
        v-scroll-to="'#section-service-group-3'"
        ><span
          class="dot"
          :class="[(isLightTheme ? 'light-theme' : 'dark-theme'), (activeIndex === 3 ? 'active' : '')]"
        ></span
      ></router-link>
    </li>
  </ul>
</template>

<script>
export default {
  name: "SectionDots",
  props: {
    activeIndex: Number,
    isLightTheme: Boolean,
  },
};
</script>

<style lang="scss" scoped>
//dots
ul {
  list-style-type: none;
}

.dot {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  display: inline-block;
}

.light-theme {
  background-color: $zim-white;
}

.dark-theme {
  background-color: $zim-navy;
}

.active {
  background-color: $zim-blue;
}
</style>
