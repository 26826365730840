<template>
  <div class="col-md-2 p-5">
    <router-link :to="'#' + routerTo" v-scroll-to="'#' + routerTo">
      <img
        class="img-responsive mx-auto d-block mb-4 animate__animated animate__bounce highlight-img"
        :src="imageSrc"
        :alt="imageAlt"
      />
      <h3 class="text-center">{{ title }}</h3>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "Highlights",
  props: {
    title: String,
    imageSrc: String,
    imageAlt: String,
    routerTo: String,
  },
};
</script>

<style lang="scss" scoped>
@import "~bootstrap/scss/bootstrap.scss";
h3 {
  font-size: 1rem !important;
  color: $zim-text-dark;
}

a {
  text-decoration: none;
}

.highlight-img {
  max-width: 125px;
}

@include media-breakpoint-down(lg) {
  .highlight-img {
    max-width: 80px;
  }
}
</style>
