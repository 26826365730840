<template>
  <div id="portfolio" class="container-fluid">
    <div class="row">
      <div class="col-xl-6 align-self-center carousel">
        <b-carousel
          id="ourwork-carousel"
          :interval="3000"
          fade
          indicators
          controls
        >
          <b-carousel-slide
            v-for="(image, imageIndex) in imgSrc"
            :key="imageIndex"
            :img-src="image"
          >
          </b-carousel-slide>
        </b-carousel>
      </div>
      <div class="col-xl-6">
        <div class="m-5">
          <a :href="website" target="_blank" rel="noopener"
            ><h1 class="brand-name pb-3" :class="getBrandNameClass()">
              {{ name }}
            </h1></a
          >
          <h4 class="pb-3">{{ tagline }}</h4>
          <p class="pb-3">
            {{ description }}
          </p>
          <h4 :style="serviceHeadingOverride" class="services-heading pb-3">
            Services we provided:
          </h4>
          <ul class="provided-services-ul pb-3">
            <div class="row">
              <li
                v-for="item in providedServices"
                :key="item.key"
                :class="getProvidedServicesItemClass()"
                class="provided-services-item col-md-6"
              >
                {{ item }}
              </li>
            </div>
          </ul>

          <p v-if="website">
            <font-awesome-icon
              icon="laptop"
              size="1x"
              :style="serviceHeadingOverride"
            />
            <a :href="website" target="_blank">{{ website }}</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Portfolio",
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "Our Work",
  },
  props: ["brandName"],
  data() {
    return {
      name: "",
      tagline: "",
      description: "",
      providedServices: [],
      color: "",
      website: "",
      imgSrc: [],
    };
  },
  mounted() {
    this.$ga.page("/portfolio");
    window.scrollTo(0, 0); //scroll to top of page

    this.overridgeTopbarBackgroundColor();
  },
  created() {
    let brandName = this.$props.brandName.toLowerCase();

    if (brandName === "glow-up") {
      this.name = "Glow Up";
      this.tagline = "Glowing skin is always in.";
      this.description =
        "Upon our initial consultation with Faye, it was clear she had a strong idea + was super passionate about the physical direction of her business - helping people achieve beautiful skin through advanced skincare treatments. Faye needed our help to establish a strong brand identity that was representative of her market + values, whilst being one that would attract her ideal clientele (primarily women, aged 18-35 based in Wigan, Manchester + Liverpool). ";
      this.providedServices.push(
        "Brand Guidelines",
        "Website Design",
        "Website Development",
        "Logo + Submarks",
        "Instagram Highlight Covers",
        "Loyalty Cards",
        "Aftercare Cards",
        "Gift Vouchers",
        "Promotional Flyers",
        "Posters"
      );
      this.website = "http://glowupskin.co.uk/";
      this.color = "#F49E72"; //$zim-orange
      this.discImage = "~@/assets/discs/disc-pink.svg";
      this.imgSrc.push(
        require("@/assets/our-work/glow/glow-1.jpg"),
        require("@/assets/our-work/glow/glow-2.jpg"),
        require("@/assets/our-work/glow/glow-3.jpg")
      );
    }

    if (brandName === "ring-revolution") {
      this.name = "Ring Revolution";
      this.tagline = "Handcrafted custom rings for every ocassion.";
      this.description =
        "Jamie (the owner of Ring Revolution) came to us with a business he was running, with a logo he had quickly knocked up. He wasn’t sure of the direction of his brand other than a muted colour scheme with strong elements to allow his custom pieces to do the talking. We worked together to create something super minimal yet memorable. After we had established the brand + collateral, we created an e-commerce website to showcase his work + create a platform to sell his products. ";
      this.providedServices.push(
        "Brand Guidelines",
        "Logo + Submarks",
        "Thank You Cards",
        "Website Design",
        "Ecommerce Website",
        "Round the clock Support",
        "Ecommerce Platform Training",
        "Compliance & Regulations Support"
      );
      this.website = "https://www.ringrevolution.co.uk";
      this.color = "#5FC1CA"; //$zim-blue
      this.imgSrc.push(
        require("@/assets/our-work/ring-revolution/ring-revolution1.jpg"),
        require("@/assets/our-work/ring-revolution/ring-revolution2.jpg"),
        require("@/assets/our-work/ring-revolution/ring-revolution3.jpg")
      );
    }

    if (brandName === "mama-gs") {
      this.name = "Mama G’s";
      this.tagline = "Traditional, home-made Brazilian snacks.";
      this.description =
        "Getter is a renowned head chef + baker. After being in the industry for many years, she wanted to establish her own brand to share the delights that she makes at home with the world. Getter asked for a bold + bright brand identity that would appeal to the masses whilst giving a visual nod to Brazil without being dated. We worked with Getter to do exactly this. Her brand reflects her personality, the vibrancy of the food she makes + the happiness in receiving delicious snacks from Mama G’s. There’s lots more to come so keep your eyes peeled!";
      this.providedServices.push(
        "Brand Guidelines",
        "Logo + Submarks",
        "Business Cards",
        "Thank You / Allergy Cards"
      );
      this.color = "#E6600E"; //$zim-blue
      this.imgSrc.push(
        require("@/assets/our-work/mama-gs/mama-gs1.jpg"),
        require("@/assets/our-work/mama-gs/mama-gs2.jpg")
      );
    }
  },
  computed: {
    serviceHeadingOverride() {
      return {
        color: this.color,
      };
    },
    setBackgroundColor() {
      return {
        "background-color": "red",
      };
    },
  },
  methods: {
    getProvidedServicesItemClass: function() {
      switch (this.brandName) {
        case "ring-revolution":
          return "provided-services-item-blue";
        case "glow-up":
          return "provided-services-item-orange";
        case "mama-gs":
          return "provided-services-item-orange";
        default:
          return "provided-services-item-pink";
      }
    },
    getBrandNameClass: function() {
      switch (this.brandName) {
        case "ring-revolution":
          return "brand-name-blue";
        case "glow-up":
          return "brand-name-orange";
        case "mama-gs":
          return "brand-name-orange";
        default:
          return "brand-name-pink";
      }
    },
    overridgeTopbarBackgroundColor: function() {
      var topbarHeader = document.getElementById("topbar-header");
      topbarHeader.classList.add("topbar-header-override");
    },
  },
};
</script>

<style lang="scss" scoped>
//import bootstrap and bootstrap-vue required varaibles
@import "~bootstrap/scss/bootstrap.scss";


.container-fluid {
  background-color: $zim-white;
  padding-top: 100px; //required to add spacing to allow to static navbar
}

@include media-breakpoint-down(sm) {
  .container-fluid {
    padding-top: 95px; //required to add spacing to allow to static navbar
  }
}

h1 {
  font-weight: 900;
}

h4 {
  font-weight: 700;
}

.brand-name {
  text-decoration: none;
}
a {
  color: inherit;
}
a:hover {
  text-decoration: none;
  color: inherit;
}

.brand-name-blue:before {
  content: "";
  position: absolute;
  width: 30%;
  height: 5px;
  background-color: $zim-blue;
  top: 10%;
  right: -10%;
}

.brand-name-orange:before {
  content: "";
  position: absolute;
  width: 30%;
  height: 5px;
  background-color: $zim-orange-strong;
  top: 10%;
  right: -10%;
}

.brand-name-pink:before {
  content: "";
  position: absolute;
  width: 30%;
  height: 5px;
  background-color: $zim-pink;
  top: 5%;
  right: -10%;
}

.services-heading {
  color: $zim-blue;
}

.provided-services-ul {
  padding-inline-start: 0px;
}

.provided-services-item {
  list-style: none;
}

.provided-services-item-blue::before {
  content: url("~@/assets/discs/disc-blue.svg");
  display: inline-block;
  margin-right: 10px;
  width: 15px;
}

.provided-services-item-orange::before {
  content: url("~@/assets/discs/disc-orange.svg");
  display: inline-block;
  margin-right: 10px;
  width: 15px;
}

.carousel {
  padding: 0;
}
</style>
