<template>
  <div id="contact" class="container-fluid">
    <div class="container pt-5 pb-5 contact-container-pb">
      <div class="row ml-5 contact-m">
        <div
          class="col"
          v-animate-onscroll.repeat="'animate__animated animate__fadeIn'"
        >
          <h2>Contact Us<span>:</span></h2>
        </div>
      </div>

      <div class="row ml-5 contact-m">
        <!-- first columns (form) -->
        <div class="col-sm-6">
          <ContactForm />
        </div>

        <!-- second column (Instagram) -->
        <div class="col-sm-6">
          <!-- <InstagramFeed /> -->
          <ContactIcons />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Contact",
  components: {
    // InstagramFeed
    ContactForm,
    ContactIcons,
  },
};

// import InstagramFeed from "./InstagramFeed";
import ContactForm from "./ContactForm";
import ContactIcons from "./ContactIcons";
</script>

<style lang="scss" scoped>
.container-fluid {
  background-color: $zim-navy;
  color: $zim-white;
}

h2 > span {
  color: $zim-blue;
}

// Small devices (landscape phones, 576px)
@media screen and (max-width: 576px) {
  .contact-container-pb {
    padding-bottom: 0.5rem !important;
  }

  .contact-m {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
}
</style>
