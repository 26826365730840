<template>
  <div>
    <p class="m-5 contact-icons-m">
      Zim Digital are a Branding and Digital Agency based in Wigan, Greater
      Manchester. We pride ourselves on our ability to turn businesses into
      brands. For more info on us and our services,
      <span class="blue-text">get in touch today!</span>
    </p>

    <div class="m-5 contact-icons-m">
      <a
        href="https://www.instagram.com/thisiszim/"
        target="_blank"
        rel="noopener"
      >
        <img
          class="img-fluid social-icon mr-2"
          :src="require('@/assets/social-media/instagram.svg')"
          alt="Zim Digital Instagram"
        />
      </a>

      <a
        href="https://www.facebook.com/thisiszim/"
        target="_blank"
        rel="noopener"
      >
        <img
          class="img-fluid social-icon m-2"
          :src="require('@/assets/social-media/facebook.svg')"
          alt="Zim Digital Facebook"
        />
      </a>

      <a href="#">
        <img
          class="img-fluid social-icon m-2"
          :src="require('@/assets/social-media/linkedIn.svg')"
          alt="Zim Digital LinkedIn"
        />
      </a>
    </div>

    <div class="mt-5 ml-5 mb-1 contact-icons-m">
      <a href="mailto:hello@zimdigital.co.uk">
        <font-awesome-icon icon="envelope" size="lg" class="mail-icon" />
        <span class="ml-2">hello@zimdigital.co.uk</span></a
      >
    </div>

    <div class="ml-5 mb-1 contact-icons-m">
      <a href="tel:07702671940">
        <font-awesome-icon icon="phone" size="lg" class="mail-icon" />
        <span class="ml-2">07702671940</span>
      </a>
    </div>

    <address>
      <div class="ml-5 mb-1 contact-icons-m">
        <a href="https://g.page/zimdigital?share" target="_blank">
          <font-awesome-icon icon="map-marker" size="lg" class="mail-icon" />
          <span class="ml-3">58 Wigan Road, Shevington, Wigan, WN6 8AP</span>
        </a>
      </div>
    </address>

    <div></div>
  </div>
</template>

<script>
export default {
  name: "ContactIcons",
};
</script>

<style lang="scss" scoped>
a {
  color: $zim-white;
}
a:hover {
  color: $zim-pink;
  text-decoration: none;
}

h2 > span {
  color: $zim-blue;
}

.mail-icon {
  color: $zim-pink;
}

.blue-text {
  color: $zim-blue;
}

.social-icon {
  max-width: 35px !important;
}

// Small devices (landscape phones, 576px)
@media screen and (max-width: 576px) {
  .contact-icons-m {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
}
</style>
