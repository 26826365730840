<template>
  <div id="services" class="container-fluid">
    <div class="container pt-5 pb-5 services-p">
      <div class="row">
        <div class="col">
          <h2
            class="text-center"
            v-animate-onscroll.repeat="'animate__animated animate__fadeIn'"
          >
            What We Do<span>:</span>
          </h2>
        </div>
      </div>
      <div class="row m-5">
        <p class="text-center">
          We are a Brand and Digital Agency based in Wigan. Our main focus at
          Zim Digital is to make your business into a powerful brand. We provide
          an invaluable range of services that meet your specific requirements
          and set you apart from your competition.
        </p>
      </div>

      <div class="row justify-content-center m-5">
        <div class="col-md-3 mt-4 text-center service-section">
          <img
            class="img-fluid"
            v-animate-onscroll.repeat="'animate__animated animate__fadeIn animate__slower'"
            :src="require('@/assets/serviceIcons/brand-identity.png')"
            alt="Brand Identity"
            width="200px"
          />
          <h3 class="mt-3">Brand Identity Wigan</h3>
          <p class="mt-3">
            Establishing a strategic + valuable visual representation for your
            business. From concept to completion, we’ve got your brand covered!
          </p>
        </div>

        <div class="col-md-3 mt-4 text-center service-section">
          <img
            class="img-fluid"
            v-animate-onscroll.repeat="'animate__animated animate__fadeIn animate__slower'"
            :src="require('@/assets/serviceIcons/graphic-design.png')"
            alt="Graphic Design"
            width="200px"
          />
          <h3 class="mt-3">Graphic Design Wigan</h3>
          <p class="mt-3">
            Creating alluring marketing + print material that outshines your
            competitors whilst being a cohesive + reflective asset to your
            business.
          </p>
        </div>
        <div class="col-md-3 mt-4 text-center service-section">
          <router-link to="/services/web" v-scroll-to="'#web-services'">
            <img
              class="img-fluid"
              v-animate-onscroll.repeat="'animate__animated animate__fadeIn animate__slower'"
              :src="require('@/assets/serviceIcons/web-design.png')"
              alt="Website Design"
              width="200px"
            />

            <h3 class="mt-3">Web Design Wigan</h3>
            <p class="mt-3">
              The window to your business. We create brand reflective, memorable
              websites to increase credibility + to provide an online +
              informative presence + We do more with Web than just websites.
            </p>
          </router-link>
        </div>
        <!-- <div class="col-md-4 mt-4 text-center service-section">
          <img
            class="img-fluid"
            v-animate-onscroll.repeat="'animate__animated __fadeIn'"
            :src="require('@/assets/serviceIcons/social-media.png')"
            alt="Social Media"
            width="200px"
          />
          <h4 class="mt-3">Social Media</h4>
          <p class="mt-3">
            We work with you to create + manage content for your platforms that
            reinforce your message, communicate with potential clients +
            increase your engagement.
          </p>
        </div> -->
        <div class="col-md-3 mt-4 text-center service-section">
          <router-link to="/services/software-development">
            <img
              class="img-fluid"
              v-animate-onscroll.repeat="'animate__animated animate__fadeIn'"
              :src="require('@/assets/serviceIcons/bespoke-software.png')"
              alt="Bespoke Software"
              width="200px"
            />
            <h3 class="mt-3">Software Development Company</h3>
            <p class="mt-3">
              Software built to your requirments. Whether you'd like to automate
              your business processes or bring an idea to life, we can help.
            </p>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Services",
};
</script>

<style lang="scss" scoped>
.container-fluid {
  background-color: $zim-navy;
  color: $zim-white;
}

h2 > span {
  color: $zim-blue;
}

a {
  color: $zim-pink;
}
:hover {
  color: $zim-white;
}

.service-section {
  a {
    color: $zim-white !important;
  }
}
:hover {
  text-decoration: none;
  color: $zim-white;
}

// Small devices (landscape phones, 576px)
@media screen and (max-width: 576px) {
  .services-p {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .service-section {
    padding-left: 0;
    padding-right: 0;
  }
}
</style>
