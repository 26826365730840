<template>
  <router-link
    :to="routerToLocation"
    :v-scroll-to="routerToLocation"
    class="btn btn-default"
    :class="btnThemeClass"
    >{{ buttonText }}</router-link
  >
</template>

<script>
export default {
  name: "RouterLinkButton",
  props: {
    routerToLocation: String,
    buttonText: String,
    theme: String,
  },
  computed: {
    btnThemeClass: function() {
      return this.getCssTheme();
    },
  },
  methods: {
    getCssTheme: function() {
      switch (this.theme) {
        case "light":
          return "btn-light-theme";
        case "dark":
          return "btn-dark-theme";
        case "blue":
          return "btn-blue-theme";
        default:
           return "btn-light-theme";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-default {
  border: none;
  border-radius: 25px;
  padding: 10px 30px 10px 30px;
  font-size: 1.2rem;
  font-weight: 700;
}

.btn-light-theme {
  background-color: $zim-white;
  color: $zim-navy;
}
.btn-light-theme:hover {
  background-color: $zim-pink-strong;
  color: $zim-white;
}

.btn-dark-theme {
  background-color: $zim-navy;
  color: $zim-white;
}
.btn-dark-theme:hover {
  background-color: $zim-pink-strong;
  color: $zim-white;
}

.btn-blue-theme {
  background-color: $zim-blue;
  color: $zim-white;
}
.btn-blue-theme:hover {
  background-color: $zim-pink-strong;
  color: $zim-white;
}
</style>
