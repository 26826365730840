<template>
  <div id="web-services" class="web-services container-fluid">
    <ServiceBanner
      heading="We specialise in all things Web"
      tagline="   We are a Brand & Digital Agency based in Wigan, equipped with the
              skillset to provide services in Website Design, Website
              Development and Bespoke Website Applications plus more much."
      arrowLinkToId="#design-and-development"
    />

    <!-- icon highlights -->
    <section
      id="service-highlights"
      class="highights d-none d-xl-block d-lg-block"
    >
      <div class="row">
        <Highlights
          title="Web Design & Development"
          :imageSrc="
            require('@/assets/serviceIcons/web/web-design-and-development-150.png')
          "
          imageAlt=""
          routerTo="design-and-development"
        />
        <Highlights
          title="Content Management Systems"
          :imageSrc="require('@/assets/serviceIcons/web/cms-150.png')"
          imageAlt="Computer screen with a Content Management System web page open."
          routerTo="content-management-systems"
        />
        <Highlights
          title="E-commerce Websites"
          :imageSrc="
            require('@/assets/serviceIcons/web/ecommerce-basket-150.png')
          "
          imageAlt="Shopping basket icon representing an E-commerce basket."
          routerTo="ecommerce"
        />
        <Highlights
          title="API Development"
          :imageSrc="
            require('@/assets/serviceIcons/web/api-development-150.png')
          "
          imageAlt="Icon of a server tower representing API comminication."
          routerTo="api-development"
        />
        <Highlights
          title="Hosting & Domains"
          :imageSrc="
            require('@/assets/serviceIcons/web/hosting-and-domains-150.png')
          "
          imageAlt="Icon of a Home in the cloud representing hosting and domains."
          routerTo="hosting-and-domains"
        />
        <Highlights
          title="Bespoke Apps"
          :imageSrc="require('@/assets/serviceIcons/web/bespoke-apps-150.png')"
          imageAlt="Computer screen with a bespoke web application open."
          routerTo="bespoke-apps"
        />
      </div>
    </section>
    <!--/ end icon highlights -->

    <!-- breadcrumb -->
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link to="/">
            Home
          </router-link>
        </li>

        <li class="breadcrumb-item">
          <router-link to="/#services" v-scroll-to="'#services'">
            Services
          </router-link>
        </li>

        <li class="breadcrumb-item active" aria-current="page">Web</li>
      </ol>
    </nav>
    <!-- End breadcrumb -->

    <section id="section-service-group-1">
      <div class="row justify-content-center package-sections">
        <div class="col-lg-1"><!-- needed for offset --></div>

        <ServiceCard
          id="design-and-development"
          :iconSrc="
            require('@/assets/serviceIcons/web/web-design-and-development-50.png')
          "
          iconAlt=""
          title="Web Design & Development"
        >
          <slot>
            <p>
              Not only do our websites looks fantastic and provide a great user
              experience but they're also secure, accessible to all and
              extremely performant. We use the latest technologies combined with
              web standards to create all-round high scoring, stand-out
              websites.
            </p>
            <p>
              All of our websites are fully responsive, meaning the same site
              will work on all devices (Desktop, tables and mobiles). Not only
              will sites look good on all devices but they will also perform and
              work as expected on most Internet Browsers.
            </p>
          </slot>
        </ServiceCard>

        <ServiceCard
          id="content-management-systems"
          :iconSrc="require('@/assets/serviceIcons/web/cms-50.png')"
          iconAlt="Computer screen with a Content Management System web page open."
          title="Content Management Systems"
        >
          <slot>
            <p>
              CMS's are applications that allow clients to manage the content of
              their websites themselves. They offer the ability for clients to
              make instant changes to websites and see the content updated on
              their live website. This is great for content like blog posts or
              websites with many pages that use the same template
            </p>
            <p>
              At Zim Digital we care about a sites branding and how the website
              looks, so we tighten down our CMS's so that clients can safely
              update their websites without impacting the look or performance of
              the site.
            </p>
          </slot>
        </ServiceCard>

        <div class="col-lg-1 align-self-center d-none d-xl-block d-lg-block">
          <ServiceSectionDots :isLightTheme="false" :activeIndex="1" />
        </div>
      </div>
    </section>

    <section id="section-service-group-2">
      <div class="row justify-content-center package-sections">
        <div class="col-lg-1"><!-- needed for offset --></div>

        <ServiceCard
          id="ecommerce"
          :iconSrc="
            require('@/assets/serviceIcons/web/ecommerce-basket-50.png')
          "
          iconAlt="Shopping basket icon representing an E-commerce basket."
          title="E-Commerce"
          :isLightTheme="true"
        >
          <slot>
            <p>
              E-Commerce is the process of buying and selling goods online.
              Since the recent changes to every day life, this has quickly
              become one of our most popular services
            </p>
            <p>
              We can build E-commerce websites for all requirements, ranging
              from clients who received 1 online transaction per day to clients
              who process over 10,000 transactions per day. The Ecommerce
              platform we opt to use depends on the requirements and preference
              of the client. We also offer training and support on all Ecommerce
              platforms once we have build your website for you.
            </p>
          </slot>
        </ServiceCard>

        <ServiceCard
          id="api-development"
          :iconSrc="require('@/assets/serviceIcons/web/api-development-50.png')"
          iconAlt="Icon of a server tower representing API comminication."
          title="API Development"
          :isLightTheme="true"
        >
          <slot>
            <p>
              Application Programming Interfaces (API's) are web applications
              that allows applications to talk to one another. At Zim Digital we
              specialise in developing industry standard high performant API's
              that make communication between clients a breeze. If you are
              looking for ways to expose your data to clients or the public or
              would like to integrate your systems with another API then we can
              most certainly help.
            </p>
          </slot>
        </ServiceCard>

        <div class="col-lg-1 align-self-center d-none d-xl-block d-lg-block">
          <ServiceSectionDots :isLightTheme="true" :activeIndex="2" />
        </div>
      </div>
    </section>

    <WhatAreYouWaitingForBanner header="What Are You Waiting For?">
      <slot>
        Investing in a website will increase your <br />
        credibility & conversion rate!
      </slot>
    </WhatAreYouWaitingForBanner>

    <section id="section-service-group-3">
      <div class="row justify-content-center package-sections">
        <div class="col-lg-1"><!-- needed for offset --></div>

        <ServiceCard
          id="hosting-and-domains"
          iconAlt="Icon of a Home in the cloud representing hosting and domains."
          :iconSrc="
            require('@/assets/serviceIcons/web/hosting-and-domains-50.png')
          "
          title="Hosting & Domains"
        >
          <slot>
            <p>
              We offer hosting with all the websites and applications that we
              build. We make it our responsibility to ensure that the websites
              and applications remain performant and available at all times. The
              hosting plans we offer are recommended dependant on the project
              and can be scaled to suit all requirements.
            </p>
            <p>
              Your domain name is the name that customers and clients use to
              identify your brand on the Web, so it is important that your
              domain name reflects your brand. You can purchase a domain through
              Zim Digital and we can offer advise on choosing the correct domain
              name. We can also work with existing domain names so not to worry
              if you're coming to us with an existing domain name.
            </p>
          </slot>
        </ServiceCard>

        <ServiceCard
          id="bespoke-apps"
          :iconSrc="require('@/assets/serviceIcons/web/bespoke-apps-50.png')"
          iconAlt="Computer screen with a bespoke web application open."
          title="Bespoke Apps"
        >
          <slot>
            <p>
              Not all client requirements can be meet with off-the-shelf
              products. At Zim Digital we have the skillset to build software to
              the requirements of our clients. With our talented team of
              Software Developers and Project Managers who thrive in building
              bespoke applications and software in an Agile environment.
            </p>
            <p>
              A website or application doesn't have to be public facing, we can
              build website and web applications that can act as tools for your
              own organisation or clients.
            </p>
            <p>
              If Web isn't the solution to your requirements then we can also
              build a range of other applications such as desktop applications,
              automation services, etc. You can also find more information our
              Bespoke Software service <a href="/bespoke-software">here.</a>
            </p>
          </slot>
        </ServiceCard>
        <div class="col-lg-1 align-self-center d-none d-xl-block d-lg-block">
          <ServiceSectionDots :isLightTheme="false" :activeIndex="3" />
        </div>
      </div>
    </section>

    <TimeToStandOutBanner header="It's time for you to stand out from the rest">
      <slot>
        Build trust & educate your target customers through carefully <br />
        curated & strategically designed websites
      </slot>
    </TimeToStandOutBanner>
  </div>
</template>

<script>
import ServiceBanner from "../../components/Service/ServiceBanner.vue";
import Highlights from "../../components/Service/Highlights.vue";
import ServiceCard from "../../components/Service/ServiceCard.vue";
import WhatAreYouWaitingForBanner from "../../components/banners/WhatAreYouWaitingForBanner.vue";
import TimeToStandOutBanner from "../../components/banners/TimeToStandOutBanner.vue";
import ServiceSectionDots from "../../components/utils/ServiceSectionDots.vue";

export default {
  name: "Web",
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "Web Design Wigan - Web Development Wigan",
  },
  components: {
    ServiceBanner,
    Highlights,
    ServiceCard,
    WhatAreYouWaitingForBanner,
    TimeToStandOutBanner,
    ServiceSectionDots,
  },
  mounted() {
    this.$ga.page("/serivces/web");
  },
};
</script>
<style lang="scss" scoped>
@import "~bootstrap/scss/bootstrap.scss";

.web-services {
  margin-top: 9rem;
}

@include media-breakpoint-down(xs) {
  .web-services {
    margin-top: 2rem !important;
  }
}

@include media-breakpoint-down(sm) {
  .web-services {
    margin-top: 5rem !important;
  }
}

.container-fluid {
  background-color: $zim-white;
}

h1 > span {
  color: $zim-blue;
}

.breadcrumb {
  background-color: inherit;
  font-weight: 600;

  ::before {
    color: $zim-pink-strong;
  }
}
a {
  color: $zim-pink-strong;
}

//taken from templates
.bg-tint-primary {
  background-color: $zim-navy;
  color: $zim-white;
}

//arrow
.size-50 {
  width: 50px !important;
  height: 50px !important;
}

.center-both {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
}

//hightlights
#service-highlights {
  background-color: $zim-blue;
}

#section-service-group-2 {
  background-color: $zim-navy;
}

@include media-breakpoint-up(lg) {
  .package-sections {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}
</style>
