<template>
  <div id="about" class="container-fluid">
    <div class="container pt-5">
      <div class="row ml-5">
        <div class="col">
          <h2 v-animate-onscroll.repeat="'animate__animated animate__fadeIn'">
            An Idea turned reality<span>.</span>
          </h2>
        </div>
      </div>
      <div class="row m-5 about-mr">
        <div class="col-md-6">
          <p
            class="about-first-item"
          >
            Let’s set the scene: Cyprus, September 2020.
          </p>
          <p>
            Four friends sat round a pool, beers in hand discussing what we’d
            ‘love to do’ with our careers. Each person with years upon years
            experience and determination to love our jobs and even better, have
            clients that love our work.
          </p>
          <p>
            And then it clicked; what are we waiting for?
          </p>
        </div>

        <div class="col-md-6">
          <p>
            Since that moment of clarity,
            <span class="pink-underline"
              >we’ve brought the sunshine of design home to Wigan.</span
            >
          </p>
          <p>
            As developers and creators, we continue to work with clients who are
            passionate about what they do, and trust in our ability create
            inspiring and impactful communications for their business.
          </p>
          <p>
            With that said,
            <span class="blue-text">what are you waiting for?</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "About"
};
</script>

<style lang="scss" scoped>
.container-fluid {
  //   background-color: $zim-pink;
  color: $zim-white;
}

h2 > span {
  color: $zim-blue;
}

.pink-underline {
  text-decoration: underline;
  text-decoration-color: $zim-pink;
}

.blue-text {
  color: $zim-blue;
}

.about-first-item {
  position: relative;
}

.about-first-item:before {
  content: "";
  position: absolute;
  width: 35%;
  height: 5px;
  background-color: $zim-orange-strong;
  top: 50%;
  left: -50%;
}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1500px) {
  .about-first-item:before {
    left: -100%;
  }
}

// Small devices (landscape phones, 576px)
@media screen and (max-width: 576px) {
  .about-mr {
    margin-right: 0.5rem !important;
  }
}
</style>
