var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid",attrs:{"id":"ourWork"}},[_c('div',{staticClass:"container pt-5"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('h2',{directives:[{name:"animate-onscroll",rawName:"v-animate-onscroll.repeat",value:('animate__animated animate__fadeIn'),expression:"'animate__animated animate__fadeIn'",modifiers:{"repeat":true}}],staticClass:"text-center"},[_vm._v("\n          Our Work"),_c('span',[_vm._v(":")])])])]),_vm._v(" "),_c('div',{staticClass:"row justify-content-center mt-5 ml-5 mr-5 ourwork-covers-m"},[_c('div',{staticClass:"col-md-4 p-3 cover"},[_c('img',{directives:[{name:"animate-onscroll",rawName:"v-animate-onscroll.repeat",value:('animate__animated animate__fadeInLeft'),expression:"'animate__animated animate__fadeInLeft'",modifiers:{"repeat":true}}],staticClass:"img-fluid cover-img",attrs:{"src":require('@/assets/our-work/covers/ring-revolution-cover.svg'),"alt":"Ring Revolution Cover"}}),_vm._v(" "),_c('div',{staticClass:"cover-learn-more btn btn-learn-more"},[_c('router-link',{attrs:{"to":{
              name: 'portfolio',
              params: { brandName: 'ring-revolution' },
            }}},[_vm._v("\n            See More\n          ")])],1)]),_vm._v(" "),_c('div',{staticClass:"col-md-4 p-3 cover"},[_c('img',{directives:[{name:"animate-onscroll",rawName:"v-animate-onscroll.repeat",value:('animate__animated animate__fadeInDown'),expression:"'animate__animated animate__fadeInDown'",modifiers:{"repeat":true}}],staticClass:"img-fluid cover-img",attrs:{"src":require('@/assets/our-work/covers/glow-up-cover.svg'),"alt":"Glow Cover"}}),_vm._v(" "),_c('div',{staticClass:"cover-learn-more btn btn-learn-more"},[_c('router-link',{attrs:{"to":{
              name: 'portfolio',
              params: { brandName: 'glow-up' },
            }}},[_vm._v("\n            See More\n          ")])],1)]),_vm._v(" "),_c('div',{staticClass:"col-md-4 p-3 cover"},[_c('img',{directives:[{name:"animate-onscroll",rawName:"v-animate-onscroll.repeat",value:('animate__animated animate__fadeInRight'),expression:"'animate__animated animate__fadeInRight'",modifiers:{"repeat":true}}],staticClass:"img-fluid cover-img",attrs:{"src":require('@/assets/our-work/covers/mama-gs-cover.svg'),"alt":"Glow Cover"}}),_vm._v(" "),_c('div',{staticClass:"cover-learn-more btn btn-learn-more"},[_c('router-link',{attrs:{"to":{
              name: 'portfolio',
              params: { brandName: 'mama-gs' },
            }}},[_vm._v("\n            See More\n          ")])],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }