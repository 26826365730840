<template>
  <section id="section-waywf">
    <div class="row text-center">
      <div class="col">
        <h3 class="pt-5 pb-4 heading-waywf">{{ header }}</h3>
        <h3 class="pb-4 tagline">
          <slot>
            Please provide a tagline via a slot.
          </slot>
        </h3>

        <div class="pb-5">
          <RouterLinkButton
            buttonText="I'M READY!"
            routerToLocation="/#contact"
            theme="light"
          />

          <RouterLinkButton
            buttonText="BOOK ME IN!"
            routerToLocation="/#contact"
            theme="dark"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import RouterLinkButton from "../../components/utils/RouterLinkButton.vue";

export default {
  name: "WhatAreYouWaitingForBanner",
  props: {
    header: String,
  },
  components: {
    RouterLinkButton,
  },
};
</script>

<style lang="scss" scoped>
//what are you waiting for banner
#section-waywf {
  background-color: $zim-blue;
  color: $zim-white !important;
}

.heading-waywf {
  color: $zim-text-dark;
  text-transform: uppercase;
  font-size: 1.1rem;
}

.tagline {
  color: $zim-white;
}
</style>
