<template>
  <div id="software-development" class="software-development container-fluid">
    <ServiceBanner
      heading="Softwate Development Solutions"
      tagline="With over 10 years experience in building enterprise level sofware applications, Zim Digital know how to answer 
the exact requirements of our clients. From internal apps that automate everyday tasks, to large public 
facing websites that can scale & maintain performance based on demand. "
      arrowLinkToId="#automating-business-processes"
    />

    <section id="section-service-group-1">
      <div class="row justify-content-center package-sections">
        <ServiceCard
          id="automating-business-processes"
          :iconSrc="require('@/assets/serviceIcons/web/bespoke-apps-50.png')"
          iconAlt=""
          title="Automating Business Processes"
        >
          <slot>
            <p>
              Many organisations still find themselves manually completing
              repetive business processes. This can become hard to work with,
              vulnerable to mistakes and ultimately be costly and extremely time
              consuming. We can supply a solution tailored to you that will
              produce the same outcome but with the click of a few buttons,
              saving you hours and giving you more time to focus on what truly
              matters. Here are some examples;
            </p>
            <ul class="pt-2">
              <li>Automating Accounting Speadsheets</li>
              <li>Order Managerment Software</li>
              <li>Custom HR Software</li>
            </ul>
          </slot>
        </ServiceCard>

        <ServiceCard
          id="bespoke-websites"
          :iconSrc="require('@/assets/serviceIcons/web/cms-50.png')"
          iconAlt="Computer screen with a Content Management System web page open."
          title="Bespoke Websites"
        >
          <slot>
            <p>
              You have an idea of a product that you want to put out to the
              market however, this isn’t your standard website. With your
              concept, there are no off-the-shelf solutions that meet your
              needs. This is where we come in. We can build any website tailored
              to your requirements and specifications to help bring your dreams
              to life.
            </p>
          </slot>
        </ServiceCard>
      </div>
    </section>

    <WhatAreYouWaitingForBanner header="CAN’T FIND WHAT YOU’RE LOOKING FOR?">
      <slot>
        The above are purely examples! Bespoke means bespoke <br />
        so whatever your idea is, we can bring it to life!
      </slot>
    </WhatAreYouWaitingForBanner>

    <section>
      <div class="container">
        <h2 class="text-center p-5">
          What makes Zim Digital’s bespoke software different?
        </h2>

        <ul class="provided-services-ul pb-5 mb-0">
          <div class="row">
            <li class="provided-services-item col-md-6 p-3">
              Our automation processes that allow us to regularly deliver error
              free changes to your software that can be
              <span class="bold">made live with a click of a button</span>.
              You’ll beat competitors to the market without waiting around for
              the next scheduled release date.
            </li>

            <li class="provided-services-item col-md-6 p-3">
              We always focus on delivering the minimum valuable product before
              any additional
              <span class="bold">‘nice to have’</span>
              features are added, to ensure clients are benefiting from a return
              on investment from the get-go.
            </li>

            <li class="provided-services-item col-md-6 p-3">
              We continually liaise with clients & provide them with a platform
              to monitor project
              <span class="bold">
                progress giving an early feel for the product</span
              >
              - think of this as your playground!
            </li>

            <li class="provided-services-item col-md-6 p-3">
              Upon project completion & sign-over,
              <span class="bold">the software is completely yours</span>. Under
              contract we will provide around-the-clock support as well as,
              dedicated time each month to maintain and update your software
            </li>

            <li class="provided-services-item col-md-6 p-3">
              All our projects are developed with care and testing in mind. We
              strive to ensure that there are no bugs with the software.
              <span class="bold"
                >Any errors are identified before the software goes live</span
              >
            </li>

            <li class="provided-services-item-no-disc col-md-6 p-3">
              <p class="get-in-touch bold">
                If you’re ready to conceptualise your idea, get in touch with a
                member of our Bespoke Software Development team in Wigan!
              </p>
              <p class="bold">
                <a href="mailto:hello@zimdigital.co.uk"> hello@zimdigital.co.uk </a>
              </p>
            </li>
          </div>
        </ul>
      </div>
    </section>
  </div>
</template>

<script>
import ServiceBanner from "../../components/Service/ServiceBanner.vue";
import ServiceCard from "../../components/Service/ServiceCard.vue";
import WhatAreYouWaitingForBanner from "../../components/banners/WhatAreYouWaitingForBanner.vue";

export default {
  Name: "SoftwareDevelopment",
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "Software Development Comanpany - Bespoke Software Wigan",
  },
  components: {
    ServiceBanner,
    ServiceCard,
    WhatAreYouWaitingForBanner,
  },
  mounted() {
    this.$ga.page("/serivces/software-development");
  },
};
</script>

<style lang="scss" scoped>
@import "~bootstrap/scss/bootstrap.scss";

h2 {
  font-weight: 900 !important;
}

.software-development {
  margin-top: 9rem;
}

@include media-breakpoint-down(xs) {
  .software-development {
    margin-top: 2rem !important;
  }
}

@include media-breakpoint-down(sm) {
  .software-development {
    margin-top: 5rem !important;
  }
}

.container-fluid {
  background-color: $zim-white;
}

a {
  color: $zim-pink-strong;
}

@include media-breakpoint-up(lg) {
  .package-sections {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

.bold {
  font-weight: bold;
}

.provided-services-ul {
  padding-inline-start: 0px;
}

.provided-services-item {
  list-style: none;
}

.provided-services-item::before {
  content: url("~@/assets/discs/disc-blue.svg");
  display: inline-block;
  margin-right: 10px;
  width: 15px;
}

.provided-services-item-no-disc {
  list-style: none;
}

.get-in-touch{
  color: $zim-blue;
}

a {
  color: $zim-text-dark;
}
a:hover {
  color: $zim-pink;
  text-decoration: none;
}

</style>
