<template>
  <section class="position-relative bg-tint-primary">
    <div class="container pt-5 position-relative z-index-1">
      <div class="row pb-5 align-items-center">
        <div class="col-lg-8 mx-auto text-center">
          <h1 class="mb-4">{{ heading }}<span>.</span></h1>
          <p class="mb-5 lead w-lg-75 mx-auto">
            {{ tagline }}
          </p>

          <router-link
            :to="arrowLinkToId"
            v-scroll-to="arrowLinkToId"
            class="size-50 rounded-circle center-both d-flex text-center mx-auto animate__animated animate__bounce animate__slow animate__infinite"
          >
            <div class="link-arrow-bounce">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="35"
                height="35"
                fill="currentColor"
                class="bi bi-chevron-double-down"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M1.646 6.646a.5.5 0 0 1 .708 0L8 12.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                ></path>
                <path
                  fill-opacity=".4"
                  fill-rule="evenodd"
                  d="M1.646 2.646a.5.5 0 0 1 .708 0L8 8.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                ></path>
              </svg>
            </div>
          </router-link>
        </div>
      </div>
      <!--/.end-row--->
    </div>
    <!--/.End-content-->
  </section>
</template>

<script>
export default {
  name: "SerivceBanner",
  props: {
    heading: String,
    tagline: String,
    arrowLinkToId: String,
  },
};
</script>

<style lang="scss" scoped>

a{
    color: $zim-pink-strong;
}:hover{
    color: $zim-white;
}

//overriden from templates
.bg-tint-primary {
  background-color: $zim-navy;
  color: $zim-white;
}

//arrow
.size-50 {
  width: 50px !important;
  height: 50px !important;
}

.center-both {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
}

</style>
