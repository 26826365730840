<template>
  <div>
    <div class="row mt-5 contact-form-mt">
      <div class="col">
        <!-- start of form -->
        <b-form
          @submit="onSubmit"
          class="needs-validation"
          novalidate
        >
          <!-- name -->
          <div class="form-group">
            <b-form-input
              id="form-name"
              name="from_name"
              v-model="form.name"
              type="text"
              placeholder="Name:"
              class="form-control form-control-lg"
              :class="{ 'is-invalid': submitted && $v.form.name.$error }"
              required
            ></b-form-input>
            <div
              v-if="submitted && !$v.form.name.required"
              class="invalid-feedback"
            >
              Name is required
            </div>
          </div>

          <!-- email -->
          <div class="form-group">
            <b-form-input
              id="form-email"
              name="from_email"
              v-model="form.email"
              type="email"
              placeholder="Email:"
              class="form-control form-control-lg"
              :class="{ 'is-invalid': submitted && $v.form.email.$error }"
              required
            ></b-form-input>
            <div
              v-if="submitted && !$v.form.email.required"
              class="invalid-feedback"
            >
              Email is required
            </div>
            <div
              v-if="submitted && !$v.form.email.email"
              class="invalid-feedback"
            >
              Email is invalid
            </div>
          </div>

          <!-- serivce of interest -->
          <div class="form-group">
            <b-form-select
              id="form-serviceOfInterest"
              name="serviceOfInterest"
              ref="form-serviceOfInterest"
              class="form-control form-control-lg"
              :class="{
                'is-invalid': submitted && $v.form.serviceOfInterest.$error,
              }"
              v-model="form.serviceOfInterest"
              :options="servicesOfInterest"
              required
            ></b-form-select>
            <div
              v-if="submitted && !$v.form.serviceOfInterest.required"
              class="invalid-feedback"
            >
              Service of Interest is required
            </div>
          </div>

          <!-- message -->
          <div class="form-group">
            <b-form-textarea
              id="form-message"
              name="message"
              v-model="form.message"
              type="textarea"
              rows="3"
              placeholder="Message:"
              class="form-control form-control-lg"
              :class="{ 'is-invalid': submitted && $v.form.message.$error }"
              required
            ></b-form-textarea>
            <div
              v-if="submitted && !$v.form.message.required"
              class="invalid-feedback"
            >
              Message is required
            </div>
          </div>

          <!-- submit button -->
          <b-button
            id="form-send-btn"
            type="submit"
            variant="primary"
            class="btn-send"
            >Send</b-button
          >

          <p class="pl-2 pt-2" v-if="submitStatus === 'ok'">
            Thanks for your message!
          </p>
          <p class="pl-2 pt-2" v-if="submitStatus === 'error'">
            There was an issing sending your message. Please send your message
            to
            <a href="mailto:hello@zimdigital.co.uk">hello@zimdigital.co.uk</a>.
          </p>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
import emailjs from "emailjs-com";

export default {
  name: "ContactForm",
  data() {
    return {
      form: {
        email: "",
        name: "",
        serviceOfInterest: null,
        message: "",
      },
      submitted: false,
      submitStatus: null,
      servicesOfInterest: [
        { text: "Service of Interest:", value: null },
        "Branding",
        "Website",
        "ECommerce",
        "Bespoke",
        "Other",
      ],
    };
  },
  mounted() {
    var serviceOfInterestSelectEl = this.$refs["form-serviceOfInterest"].$el;
    serviceOfInterestSelectEl.classList.remove("custom-select"); //remove vueBootstrap class that is assigned
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();

      this.submitted = true;

      this.$v.$touch();
      if (this.$v.$invalid) {
        // return here if form is invalid
        return;
      }

      //send email
      var serviceId = "service_skssz7w";
      var templateId = "template_bk28cae";
      var userId = "user_GaXVIVOIcwqek1GN59Qon";

      emailjs.sendForm(serviceId, templateId, event.target, userId).then(
        (result) => {
          console.log("email sent!", result.status, result.text);
          this.submitStatus = "ok";

          this.submitted = false; //turn of validation

          // Reset form field
          this.form.name = "";
          this.form.email = "";
          this.form.serviceOfInterest = null;
          this.form.message = "";
        },
        (error) => {
          this.submitStatus = "error";
          console.log("error sending email!", error);
        }
      );
    },
  },
  validations: {
    form: {
      name: {
        required,
      },
      email: {
        required,
        email,
      },
      serviceOfInterest: {
        required,
      },
      message: {
        required,
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-send {
  background-color: $zim-pink;
  border: none;
  border-radius: 25px;
  width: 100px;
}

.btn-send:hover,
.btn-send:focus,
.btn-send:active,
.btn-send:active:focus {
  color: #fff;
  background-color: $zim-orange;
  border-color: none !important;
  outline: none !important;
  box-shadow: none;
}

//validation
.invalid-feedback {
  color: $zim-pink;
}

.form-control.is-invalid {
  border-color: $zim-pink;
}

p > a {
  color: $zim-orange;
}

// Small devices (landscape phones, 576px)
@media screen and (max-width: 576px) {
  .contact-form-mt {
    margin-top: 1.5rem !important;
  }
}
</style>
