<template>
  <div id="ourWork" class="container-fluid">
    <div class="container pt-5">
      <div class="row">
        <div class="col">
          <h2
            v-animate-onscroll.repeat="'animate__animated animate__fadeIn'"
            class="text-center"
          >
            Our Work<span>:</span>
          </h2>
        </div>
      </div>

      <div class="row justify-content-center mt-5 ml-5 mr-5 ourwork-covers-m">
        <div class="col-md-4 p-3 cover">
          <img
            class="img-fluid cover-img"
            :src="require('@/assets/our-work/covers/ring-revolution-cover.svg')"
            alt="Ring Revolution Cover"
            v-animate-onscroll.repeat="'animate__animated animate__fadeInLeft'"
          />
          <div class="cover-learn-more btn btn-learn-more">
            <router-link
              :to="{
                name: 'portfolio',
                params: { brandName: 'ring-revolution' },
              }"
            >
              See More
            </router-link>
          </div>
        </div>

        <div class="col-md-4 p-3 cover">
          <img
            class="img-fluid cover-img"
            :src="require('@/assets/our-work/covers/glow-up-cover.svg')"
            alt="Glow Cover"
            v-animate-onscroll.repeat="'animate__animated animate__fadeInDown'"
          />
          <div class="cover-learn-more btn btn-learn-more">
            <router-link
              :to="{
                name: 'portfolio',
                params: { brandName: 'glow-up' },
              }"
            >
              See More
            </router-link>
          </div>
        </div>

                <div class="col-md-4 p-3 cover">
          <img
            class="img-fluid cover-img"
            :src="require('@/assets/our-work/covers/mama-gs-cover.svg')"
            alt="Glow Cover"
            v-animate-onscroll.repeat="'animate__animated animate__fadeInRight'"
          />
          <div class="cover-learn-more btn btn-learn-more">
            <router-link
              :to="{
                name: 'portfolio',
                params: { brandName: 'mama-gs' },
              }"
            >
              See More
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OurWork",
};
</script>

<style lang="scss" scoped>
.container-fluid {
  background-color: $zim-navy;
  color: $zim-white;
}

h2 > span {
  color: $zim-blue;
}

.cover-img {
  opacity: 1;
  transition: 0.5s ease;
  backface-visibility: hidden;
  // max-width: 250px;
}

.cover-learn-more {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;

  a {
    color: $zim-white;
    background-color: $zim-pink;
    border-radius: 25px;
    padding: 5px 20px 5px 20px;
    white-space: nowrap;
    font-size: 0.8rem;
    text-decoration: none;
  }
}

.cover:hover .cover-img {
  opacity: 0.3;
}

.cover:hover .cover-learn-more {
  opacity: 1;
}

.cover-learn-more:hover {
  opacity: 1;
}

// Small devices (landscape phones, 576px)
@media screen and (max-width: 576px) {
  .ourwork-covers-m {
    margin-top: 0.5rem !important;
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }

  .cover {
    text-align: center;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
</style>
