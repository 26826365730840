<template>
  <div>
    <b-modal
      id="packages-modal"
      centered
      hide-header
      hide-footer
      modal-class="packages-modal"
    >
      <div class="text-right">
        <button class="btn-close" @click="closeModal()">x</button>
      </div>

      <h3 class="text-center mb-4">{{ title }}</h3>

      <p class="text-center">
        {{ modalContent }}
      </p>

      <div class="modal-tagline text-center m-4">
        <p>Ready to take your brand to the next level?</p>
      </div>
      <div class="text-center">
        <button @click="closeModalAndScrollToContact()" class="btn btn-enquire">
          Enquire Now!
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "PackagesModal",
  props: ["title", "modalContent"],
  methods: {
    closeModal() {
      this.$bvModal.hide("packages-modal");
    },

    closeModalAndScrollToContact() {
      this.closeModal();

      this.$scrollTo("#contact");
    },
  },
};
</script>

<style lang="scss">
.packages-modal {
  .btn-close {
    color: $zim-orange-strong;
    font-size: 1.5rem;
    font-weight: bolder;
    line-height: 1;

    border: none;
    background-color: inherit !important;
  }

  .btn-close:hover {
    color: $zim-pink-strong;
  }

  h3 {
    color: $zim-orange-strong;
  }

  .modal-tagline {
    font-weight: 700;
  }

  .btn-enquire {
    background-color: $zim-orange;
    color: $zim-white;
    border: none;
    border-radius: 25px;
    padding: 5px 20px 5px 20px;
  }

  .btn-enquire:hover,
  .btn-enquire:focus,
  .btn-enquire:active,
  .btn-enquire:active:focus {
    color: $zim-white;
    background-color: $zim-pink;
    border-color: none !important;
    outline: none !important;
    box-shadow: none;
  }
}
</style>
