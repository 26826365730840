<template>
  <div>
    <Welcome />
    <About />
    <Services />
    <Packages />
    <OurWork />
    <Contact />
  </div>
</template>

<script>
import Welcome from "@/components/Welcome.vue";
import About from "@/components/About.vue";
import Services from "@/components/Services.vue";
import Packages from "@/components/Packages.vue";
import OurWork from "@/components/OurWork.vue";
import Contact from "@/components/contact/Contact";

export default {
  name: "Home",
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "Web Design Wigan - Web Design Company - Software Development Company",
  },
  components: {
    Welcome,
    About,
    Services,
    Packages,
    OurWork,
    Contact,
  },
  mounted() {
    this.$ga.page("/index");
  },
};
</script>
