<template>
  <div class="page-not-found">
    <h1>Page Not Found</h1>
  </div>
</template>

<script>

export default {
  name: "NotFound",
  mounted() {
    this.$ga.page("/not-found");
  },
};
</script>