<template>
  <div id="welcome" class="container-fluid">
    <div class="section container d-flex">
      <div class="row text-center">
        <div class="col">
          <h1 v-animate-onscroll.repeat="'animate__animated animate__bounceIn'">
            Hi, we are Zim Digital, based in Wigan<span>.</span>
          </h1>
          <h2 class="p-5">
            We provide <span>exciting & memorable</span> digital & design
            services that reflect your business perfectly.
          </h2>
          <ul class="list-inline">
            <li class="list-inline-item">
              <router-link to="#services" v-scroll-to="'#services'"
                ><h3>Web Design & Development</h3>
              </router-link>
            </li>

            <li class="list-inline-item">
              <router-link to="#services" v-scroll-to="'#services'"
                ><h3>Branding & Content</h3></router-link
              >
            </li>

            <li class="list-inline-item">
              <router-link to="/services/software-development">
                <h3>Software Development</h3>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Welcome",
};
</script>

<style lang="scss" scoped>

.section {
  height: 100vh;
}

.container-fluid {
  color: $zim-white;
  position: relative;
}

//https://coder-coder.com/background-image-opacity/
.container-fluid::before {
  content: "";
  background-image: url("../assets/welcome/welcome-background.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.15;
  background-attachment: fixed; //allows for sticky image and scrollable text https://www.codegrepper.com/code-examples/css/css+static+background+image+with+scrolling+text

  //larger devices such as Ipad Pro
  @media screen and (max-width: 1024px) {
    background-image: url("../assets/welcome/welcome-background-lg.jpg") !important;
  }

  @media screen and (max-width: 768px) {
    background-image: url("../assets/welcome/welcome-background-md.jpg") !important;
  }

  @media screen and (max-width: 576px) {
    background-image: url("../assets/welcome/welcome-background-sm.jpg") !important;
  }
}

h1 > span {
  color: $zim-blue;
}

h2 > span {
  text-decoration: underline;
  text-decoration-color: $zim-pink;
}

// Medium devices and up (tablets, 768px and up)
@media screen and (min-width: 768px) {
  .section > .row {
    position: relative;
    top: 35%;
  }

  .list-inline > li {
    margin-right: 40px;
  }
}

// Small devices and below (landscape phones, 576px and below)
@media screen and (max-width: 576px) {
  .section > .row {
    position: relative;
    top: 15%;
  }

  ul {
    text-align: left !important;
    padding-left: 3rem;
  }

  h2{
    font-size: 1.5rem !important;
  }

  h3{
    font-size: 1.1rem !important;
  }

}

ul li a {
  color: $zim-white;
}:hover{
  text-decoration: none;
}

ul li h3::before {
  content: url("~@/assets/discs/disc-pink.svg");
  display: inline-block;
  margin-right: 10px;
  width: 25px;
}

ul li:nth-child(2) h3::before {
  content: url("~@/assets/discs/disc-blue.svg");
}

ul li:nth-child(3) h3::before {
  content: url("~@/assets/discs/disc-white.svg");
}
</style>
